// npm
import React from 'react'
import { styled } from '@mui/material'

// components
import OuterLabelButton, {
  OuterLabelButtonProps,
} from '@atoms/buttons/OuterLabelButton'

// images
import ArrowDown from '@images/icons/arrow-down.svg'

export interface MoreButtonProps extends OuterLabelButtonProps {
  label: string
}

const StyledButton = styled(OuterLabelButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  borderColor: theme.palette.common.white,
  transition: theme.transitions.create(['border-color']),
  borderWidth: '2px',
  borderStyle: 'solid',
  ['&:hover']: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
}))

const MoreButton = ({ label, ...props }: MoreButtonProps) => {
  return (
    <StyledButton label={label} {...props}>
      <ArrowDown />
    </StyledButton>
  )
}

export default MoreButton
