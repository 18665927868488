// npm
import React, { useRef } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Box, useTheme } from '@mui/material'

// root
import { pageContextProps } from '../../gatsby-node'
import Layout from '@organisms/global/Layout'

// components
import LineBreaks from '@atoms/text/LineBreaks'
import GeneralHero from '@organisms/hero/GeneralHero'
import Sections from '@molecules/sections/Sections'

interface Props {
  data: Queries.GeneralPageQuery
  pageContext: PageProps & pageContextProps
}

const ArtworkDetail = ({ data, pageContext }: Props) => {
  const page = data.datoCmsGeneralPage
  const theme = useTheme()
  const scrollRef = React.useRef<HTMLDivElement>(null)

  const scrollDown = () => {
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <GeneralHero
        smallHeading={page.heroSmallHeading}
        mainHeading={
          <LineBreaks
            text={page.pageTitleNode.childMarkdownRemark.html}
            keyId="page-title"
            breakpoint="xs"
          />
        }
        discoverLabel={page.heroMoreLabel}
        discoverButtonOnMobile
        smImage={page.smImage.gatsbyImageData}
        lgImage={page.lgImage.gatsbyImageData}
        xlImage={page.xlImage.gatsbyImageData}
        scrollFunction={scrollDown}
      />
      <Box
        sx={{ position: 'absolute', bottom: theme.headerHeight }}
        ref={scrollRef}
      />
      <Sections sections={page.sections} />
    </Layout>
  )
}

export const query = graphql`
  query GeneralPage($originalId: String!) {
    datoCmsGeneralPage(originalId: { eq: $originalId }) {
      heroMoreLabel
      heroSmallHeading
      pageTitleNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      smImage: heroImage {
        gatsbyImageData(
          width: 600
          layout: FULL_WIDTH
          imgixParams: { ar: "400:480", fit: "crop", crop: "focalpoint" }
        )
      }
      lgImage: heroImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "2:1", fit: "crop", crop: "focalpoint" }
        )
      }
      xlImage: heroImage {
        gatsbyImageData(
          width: 1200
          layout: FULL_WIDTH
          imgixParams: { ar: "810:980", fit: "crop", crop: "focalpoint" }
        )
      }
      sections {
        ... on DatoCmsSectionLeadText {
          ...sectionLeadText
        }
        ... on DatoCmsSectionImageAndText {
          ...sectionImageAndText
        }
      }
    }
  }
`

export default ArtworkDetail
