// npm
import React, { memo } from 'react'
import { styled } from '@mui/material'
import striptags from 'striptags'

// constants
import { BreakpointProps } from '@constants/breakpointProps'

const Line = styled('span', {
  shouldForwardProp: (prop) => prop !== 'breakpoint',
})<BreakpointProps>(({ breakpoint, theme }) => ({
  [theme.breakpoints.up(breakpoint)]: {
    display: 'block',
    '&:nth-of-type(even)': {
      alignSelf: 'flex-end',
    },
  },
}))

export interface LineBreaksCoreProps extends BreakpointProps {
  keyId: string
}

export interface LineBreaksProps extends LineBreaksCoreProps {
  text: string
  alternateLines?: boolean
}

const LineBlocks = ({ text, keyId, breakpoint }: LineBreaksProps) => {
  const lines = striptags(text, ['span', 'em', '<p>']).split('\n')

  return (
    <>
      {lines.map((line, index) => {
        return (
          <Line
            breakpoint={breakpoint}
            key={`${keyId}-${index}`}
            dangerouslySetInnerHTML={{
              __html: line,
            }}
          />
        )
      })}
    </>
  )
}

const LineBreaks = ({ text, keyId, breakpoint }: LineBreaksProps) => {
  return (
    <LineBlocks
      text={text}
      keyId={keyId}
      breakpoint={breakpoint}
      alternateLines={false}
    />
  )
}

export default memo(LineBreaks)
