// npm
import React, { memo } from 'react'
import loadable from '@loadable/component'

// molecules
const SectionLeadText = loadable(
  () => import('@molecules/sections/components/SectionLeadText')
)
const SectionImageAndTextText = loadable(
  () => import('@molecules/sections/components/SectionImageAndText')
)
const SectionQuote = loadable(
  () => import('@molecules/sections/components/SectionQuote')
)
const SectionRecentArtwork = loadable(
  () => import('@molecules/sections/components/SectionRecentArtwork')
)
const SectionArtworkCategories = loadable(
  () => import('@molecules/sections/components/SectionArtworkCategories')
)

type Section =
  | Queries.DatoCmsSectionLeadText
  | Queries.DatoCmsSectionQuote
  | Queries.DatoCmsSectionRecentlyAddedArtwork
  | Queries.DatoCmsSectionArtworkCategoryLanding
  | Queries.DatoCmsSectionImageAndText

export interface SectionsProps {
  sections: Section[]
  leadTextNoPaddingTop?: boolean
}

const Sections = ({ sections, leadTextNoPaddingTop }: SectionsProps) => {
  return (
    <>
      {sections.map((item: any, index: number) => {
        if (item && item.model && item.model.apiKey) {
          switch (item.model.apiKey) {
            case 'section_lead_text': {
              const section: Queries.DatoCmsSectionLeadText = item
              let sx = {}
              if (leadTextNoPaddingTop) {
                sx = {
                  sx: {
                    paddingTop: '0 !important',
                  },
                }
              }
              return (
                <SectionLeadText
                  key={section.originalId}
                  text={section.leadText}
                  smallHeading={section.smallHeading}
                  {...sx}
                />
              )
            }
            case 'section_image_and_text': {
              const section: Queries.DatoCmsSectionImageAndText = item
              return (
                <SectionImageAndTextText
                  backgroundColor={section.backgroundColour}
                  altLayout={section.alternateLayout}
                  fullImage={section.fullImage}
                  key={section.originalId}
                  text={section.textNode.childMarkdownRemark.html}
                  heading={section.heading}
                  image={section.image.gatsbyImageData}
                />
              )
            }
            case 'section_quote': {
              const section: Queries.DatoCmsSectionQuote = item
              return (
                <SectionQuote
                  key={section.originalId}
                  quote={section.quote}
                  quotee={section.quotee}
                  image={section.quoteePicture.gatsbyImageData}
                />
              )
            }
            case 'section_two_quote': {
              const section: Queries.DatoCmsSectionTwoQuote = item
              return (
                <SectionQuote
                  key={section.originalId}
                  quote={section.mainQuote}
                  quotee={section.quotee}
                  image={section.quoteePicture.gatsbyImageData}
                  organisation={section.quoteeOrganisation}
                  secondaryQuote={section.secondaryQuote}
                />
              )
            }
            case 'section_recently_added_artwork': {
              const section: Queries.DatoCmsSectionRecentlyAddedArtwork = item
              return (
                <SectionRecentArtwork
                  key={section.originalId}
                  heading={section.heading}
                  viewAllLabel={section.viewAllLabel}
                />
              )
            }
            case 'section_artwork_category_landing': {
              const section: Queries.DatoCmsSectionArtworkCategoryLanding = item
              return (
                <SectionArtworkCategories
                  key={section.originalId}
                  heading={section.heading}
                />
              )
            }
          }
        }
      })}
    </>
  )
}

export default memo(Sections)
