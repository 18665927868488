// npm
import React from 'react'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import { Box, Divider, Grid, Stack, styled, useTheme } from '@mui/material'
import { use100vh } from 'react-div-100vh'

// components
import MoreButton from '@atoms/buttons/MoreButton'
import InternalLink from '@atoms/links/InternalLink'

import { slugPrefixProps } from '@helpers/slugPrefix'

// images
import ArrowDown from '@images/icons/arrow-down.svg'

interface Props {
  smallHeading: string
  mainHeading?: string | React.ReactNode
  text?: string
  discoverLabel: string
  discoverButtonOnMobile?: boolean
  slugPrefixProps: slugPrefixProps
  smImage: IGatsbyImageData
  lgImage: IGatsbyImageData
  xlImage: IGatsbyImageData
  postText?: React.ReactNode
  scrollFunction: React.MouseEventHandler<HTMLButtonElement>
}

const ImageWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
}))

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  opacity: 0.7,
  [theme.breakpoints.down('lg')]: {
    height: '0 !important',
    paddingTop: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    height: '0 !important',
    paddingTop: '120%',
  },
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%,0)',
  },
}))

const GeneralHero = ({
  smallHeading,
  mainHeading,
  text,
  postText,
  discoverLabel,
  discoverButtonOnMobile,
  smImage,
  lgImage,
  xlImage,
  slugPrefixProps,
  scrollFunction,
}: Props) => {
  const height = use100vh()
  const theme = useTheme()

  const gridItemProps = {
    xs: 12,
    lg: 6,
  }

  // @ts-ignore
  const images = withArtDirection(getImage(xlImage), [
    {
      media: `(max-width: ${theme.breakpoints.values.sm}px)`,
      image: getImage(smImage),
    },
    {
      media: `(max-width: ${theme.breakpoints.values.lg}px)`,
      image: getImage(lgImage),
    },
  ])

  return (
    <Grid
      container
      spacing={0}
      bgcolor="secondary.main"
      color="common.white"
      sx={{
        height: '100%',
        minHeight: { xs: '100%', lg: height },
        display: { xs: 'block', lg: 'flex' },
        overflow: 'hidden',
      }}
    >
      <Grid item {...gridItemProps} sx={{ order: { xs: 1, lg: 2 } }}>
        <ImageWrapper>
          {slugPrefixProps ? (
            <InternalLink {...slugPrefixProps} aria-label="View artwork">
              <StyledGatsbyImage image={images} alt="" loading="eager" />
            </InternalLink>
          ) : (
            <StyledGatsbyImage image={images} alt="" loading="eager" />
          )}
        </ImageWrapper>
      </Grid>
      <Grid
        item
        sx={{
          order: { xs: 2, lg: 1 },
          position: 'relative',
          zIndex: 1,
        }}
        {...gridItemProps}
      >
        <Stack
          justifyContent="flex-start"
          sx={{ height: { xs: 'auto', lg: '100%' } }}
          pt={{ xs: 3, lg: 10 }}
          px={{ xs: 3, lg: 10 }}
        >
          <Box py={{ xs: 3, lg: 10 }} my="auto">
            <Stack
              direction="row"
              alignItems="center"
              ml={{ xs: -6, sm: -7, md: -8, lg: -9, lg: -10 }}
            >
              <Divider
                sx={{
                  width: theme.spacing(8),
                }}
              />
              <Box ml={2} typography="smallHeading" fontWeight="fontWeightThin">
                {smallHeading}
              </Box>
            </Stack>
            {mainHeading && (
              <Box typography="xl" mt={2}>
                {mainHeading}
              </Box>
            )}
            {text && (
              <Box typography="largeText" mt={3}>
                {text}
              </Box>
            )}

            {postText}
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifySelf="flex-end"
            py={3}
            display={{
              xs: discoverButtonOnMobile ? 'flex' : 'none',
              lg: 'flex',
            }}
            mt={discoverButtonOnMobile ? { xs: 3, lg: 0 } : 0}
          >
            <MoreButton label={discoverLabel} onClick={scrollFunction}>
              <ArrowDown />
            </MoreButton>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default GeneralHero
